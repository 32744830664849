<template>
  <b-card>
    <b-overlay
      :show="!openTickets"
      rounded="sm"
    >
      <div class="invoice-register-table pt-1">
        <!-- mobile -->
        <div class="mobileVersion">
          <div
            class="float-left ir-btn-wrapper upper-select"
          >
            <b-form-select
              v-model="category"
              :options="ticketCategory"
              style="width: 16rem"
              class="upper-select1"
              @change="searchTableCategory"
            />
            <b-form-select
              v-model="status"
              :options="statuses"
              style="width: 16rem;"
              class="upper-select2"
              @change="searchTableStatus"
            />
            <b-form-input
              v-model="searchTermTxt"
              placeholder="Search"
              type="text"
              class="d-inline-block upper-select3"
              @keyup="searchTable"
            />
          </div>
        </div>

        <!-- desktop -->
        <div class="desktopVersion">
          <div
            class="float-left ir-btn-wrapper upper-select"
          >
            <b-form-select
              v-model="category"
              :options="ticketCategory"
              style="width: 16rem"
              class="upper-select1"
              @change="searchTableCategory"
            />
            <b-form-select
              v-model="status"
              :options="statuses"
              style="width: 16rem;"
              class="upper-select2"
              @change="searchTableStatus"
            />
          </div>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="searchTermTxt"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block upper-select3"
                  @keyup="searchTable"
                />
              </div>
            </b-form-group>
          </div>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="openTickets"
          :columns="columns"
          :rows="openTickets"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-0 mytable"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <router-link :to="`/tickets/${props.row.id}`">{{ props.row.id }}</router-link>
            </span>

            <!-- Column: ticket_subject -->
            <span v-else-if="props.column.field === 'ticket_subject'">
              <router-link :to="`/tickets/${props.row.id}`"><b>{{ props.row.ticket_subject }}</b></router-link>
            </span>

            <!-- Column: ticket_assigned -->
            <span v-else-if="props.column.field === 'ticket_assigned'">
              <b-badge
                v-if="props.row.ticket_assigned_to == 'Nobody'"
                variant="light-secondary text-dark"
              >{{ props.row.ticket_assigned_to }}</b-badge>
              <b-badge
                v-else
                v-b-tooltip.hover.left
                :title="props.row.ticket_assigned_to"
                variant="secondary text-white cursor-pointer"
              >{{ modifyDisplayName(props.row.ticket_assigned_to) }}</b-badge>
            </span>

            <!-- Column: user_domain -->
            <span v-else-if="props.column.field === 'user_domain'">
              <router-link :to="`/user/details/${props.row.uid}`">{{ props.row.user_domain }}</router-link>
              <br>
              <router-link
                style="color:gray;"
                :to="`/user/details/${props.row.uid}`"
              >{{ props.row.user_email }}</router-link>
            </span>

            <span v-else-if="props.column.field === 'created_at'">
              {{ getElapseDate(props.row.created_at) }}
            </span>

            <span v-else-if="props.column.field === 'last_reply'">
              {{ (props.row.last_reply != 'No reply')?(getElapseDate(props.row.last_reply)):(props.row.last_reply) }}
            </span>

            <!-- Column: Status -->
            <span
              v-else-if="props.column.field === 'ticket_status'"
              :id="`popover-button-sync`+props.row.id"
              :style="{ cursor: user.isAdmin ? 'pointer' : 'default' }"
            >
              <b-badge :variant="statusVariant(props.row.ticket_status)">
                {{ ticketStatus(props.row) }}
                <i v-if="user.isAdmin" class="bi bi-caret-down-fill" />
              </b-badge>

              <b-popover
                v-if="user.isAdmin"
                :target="`popover-button-sync`+props.row.id"
                triggers="hover"
                placement="left"
              >
                <div style="margin:20px">
                  <span v-show="askAction">
                    <strong>Please select an action.</strong>
                    <div
                      v-for="ticket in ticketStatuses"
                      :key="ticket.id"
                    >
                      <button
                        v-if="props.row.ticket_status != ticket.code"
                        type="button"
                        :class="['btn btn-sm', `btn-${statusVariant(ticket.code)}`]"
                        style="margin:3px; width: 100%;"
                        @click="askConfirmAction(props.row.id, ticket.code)"
                      >{{ ticket.label }}</button>
                    </div>
                  </span>
                  <span v-show="askConfirm">
                    <strong>Are you sure to confirm?</strong>
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      style="margin:3px; width: 100%;"
                      :disabled="sendingConfirmYes"
                      @click="confirmYes(props.row.id)"
                    >
                      <span v-if="sendingConfirmYes">
                        <b-spinner
                          small
                          type="grow"
                        />
                        Confirming...
                      </span>
                      <span v-else>Yes</span>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-danger btn-sm"
                      style="margin:3px; width: 100%;"
                      @click="answerNo"
                    >No</button>
                  </span>
                </div>
              </b-popover>
            </span>

            <!-- Column: ticket_level -->
            <span v-else-if="props.column.field === 'level'">
              <Stars
                :just-show="false"
                :level="props.row.level"
                @updateLevel="updateLevel($event, props.row.id)"
              />
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','25','50']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BPagination, BFormSelect, BCard, BOverlay, BBadge, BFormGroup, BFormInput,
  BPopover, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@/libs/axios'
import moment from 'moment'
import store from '@/store'
import Stars from './components/StarsComponent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BBadge,
    BOverlay,
    BCard,
    // BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    // vSelect,
    BSpinner,
    BPopover,
    Stars,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      askAction: true,
      askConfirm: false,
      askReject: false,
      sendingConfirmYes: false,
      pageLength: 50,
      dir: false,
      category: 'By Category',
      ticketCategory: [
        'By Category',
      ],
      status: 'By Status',
      statuses: [
        'By Status',
      ],
      item: [],
      rows: [],
      filter: {
        issueStart: null,
        issueEnd: null,
        dueStart: null,
        dueEnd: null,
        paidOn: null,
        status: null,
        clients: null,
        invoiceType: null,
      },
      searchTerm: '',
      searchTermTxt: '',
      openTickets: null,
      ticketStatuses: [],
      activeTicket: {
        id: null,
        status: null,
      },
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
    columns() {
      const columns = [
        {
          label: '#',
          field: 'id',
          sortable: true,
          sortFn: this.sortFnID,
          tdClass: 'w-10',
        },
        {
          label: 'Date',
          field: 'created_at',
          sortable: true,
          sortFn: this.sortFnDate,
        },
        {
          label: 'Subject',
          field: 'ticket_subject',
        },
        {
          label: 'Subject',
          field: 'ticket_type',
          hidden: true,
        },
      ]

      if (this.user.userRole === 'Admin') {
        columns.push(
          {
            label: 'Assigned to',
            field: 'ticket_assigned',
            sortable: true,
            sortFn: this.sortFnAssignedTo,
          },
          {
            label: 'User',
            field: 'user_domain',
          },
          {
            label: 'Last Reply',
            field: 'last_reply',
            sortable: true,
            sortFn: this.sortFnDateReply,
          },
        )
      }

      columns.push(
        {
          label: 'Status',
          field: 'ticket_status',
        },
      )

      if (this.user.userRole === 'Admin') {
        columns.push(
          {
            label: 'Level',
            field: 'level',
          },
        )
      }
      return columns
    },
    statusVariant() {
      const statusColor = {
        waiting: 'info text-white',
        onhold: 'secondary text-white',
        in_progress: 'warning text-white',
        open: 'primary text-white',
        closed: 'danger text-white',
        permanent_closed: 'dark text-white',
      }

      return status => statusColor[status]
    },
    users() {
      return this.$store.state.users.expiredUsers
    },
    invoiceData() {
      return { merged: [] }
    },
  },
  created() {
    const { uid } = this.$route.query
    const uri = 'tickets/fetchOpenTickets'
    this.$store.dispatch(uri, uid).then(() => {
      this.openTickets = this.$store.state.tickets.openTickets.data
      this.openTickets.forEach(item => {
        if (item.ticket_type !== 'all') { this.ticketCategory.push(item.ticket_type) }
        this.statuses.push(item.ticket_status)
      })

      this.ticketCategory.sort()
      this.ticketCategory = this.sortRemoveDuplicate(this.ticketCategory)

      this.statuses.sort()
      this.statuses = this.sortRemoveDuplicate(this.statuses)
      this.openTickets = this.initSortDefaultOpen(this.openTickets)
    })

    this.$store.dispatch('tickets/fetchTicketStatuses').then(() => {
      this.ticketStatuses = this.$store.state.tickets.ticketStatuses
    })
  },
  methods: {
    updateLevel(level, id) {
      axios.post('/api/tickets/update-level', {
        ticket_id: id,
        level,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: `Ticket level updated to ${level}`,
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: 'Ticket level not updated',
              variant: 'danger',
            },
          })
        })
    },

    modifyDisplayName(name) {
      const splitName = name.split(' ')
      if (splitName.length > 1) {
        return splitName[0]
      }
      return name
    },

    async confirmYes(id) {
      /* eslint-disable */
      if(id != this.activeTicket.id) return;
      this.sendingConfirmYes = true;

      await axios.patch(`/api/tickets/${id}`, { ticket_status: this.activeTicket.status })
        .then(() => {
          const user = store.state.auth.userData
          if (user.isAdmin) {
            // eslint-disable-next-line no-unused-vars
            store.dispatch('home/fetchAdminBadges').then(response => {
              // console.log(response)
            })
          } else {
            // eslint-disable-next-line no-unused-vars
            store.dispatch('home/fetchUserBadges').then(response => {
              // console.log(response)
            })
          }

          for(let ticket of this.openTickets) {
            if (ticket.id == this.activeTicket.id) {
              ticket.ticket_status = this.activeTicket.status;
              break;
            }
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Ticket status changed',
              variant: 'success',
            },
          })
          this.askAction = true;
          this.askConfirm = false;
          this.sendingConfirmYes = false;
        })
        .catch(error => { console.log(error) })
    },
    answerNo() {
      this.askAction = true
      this.askConfirm = false
      this.askReject = false
    },
    askConfirmAction(id, code) {
      this.activeTicket.id = id;
      this.activeTicket.status = code;

      this.askAction = false
      this.askConfirm = true
      this.askReject = false
    },
    askRejectAction() {
      this.askAction = false
      this.askConfirm = false
      this.askReject = true
    },
    ticketStatus(ticket) {
      let status = ''
      if (ticket.ticket_status === 'open') status = 'Open'
      else if (ticket.ticket_status === 'closed') status = 'Closed'
      else if (ticket.ticket_status === 'onhold') status = 'On Hold'
      else if (ticket.ticket_status === 'waiting') status = 'Waiting for user reply'
      else if (ticket.ticket_status === 'in_progress') status = 'In progress'
      else if (ticket.ticket_status === 'permanent_closed') status = 'Permanently Closed'
      return status
    },
    initSortDefaultOpen(tickets) {
      const open = []
      const others = []
      const in_progress = []
      tickets.forEach(item => {
        if (item.ticket_status === 'open') { open.push(item) } else if (item.ticket_status === 'in_progress') { in_progress.push(item) } else others.push(item)
      })
      const sort1 = open.concat(in_progress)
      const finalSort = sort1.concat(others)
      return finalSort
    },
    sortFnDate(x, y, col, rowX, rowY) {
      const value1 = Date.parse(this.filterFormatDate(rowX.created_at));
      const value2 = Date.parse(this.filterFormatDate(rowY.created_at));

      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0))
    },
    sortFnDateReply(x, y, col, rowX, rowY) {
      const value1 = Date.parse(this.filterFormatDate(rowX.last_reply));
      const value2 = Date.parse(this.filterFormatDate(rowY.last_reply));

      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0));
    },
    getElapseDate(Date) {
      return moment(Date).fromNow();
    },
    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
    sortFnAssignedTo(x, y, col, rowX, rowY) {
      const value1 = rowX.ticket_assigned_to;
      const value2 = rowY.ticket_assigned_to;

      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0));
    },
    cFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    sortFnID(x, y, col, rowX, rowY) { // eslint-disable-line

      const value1 = Number(rowX.id)
      const value2 = Number(rowY.id)

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    searchTable() {
      this.category = 'By Category'
      this.status = 'By Status'
      this.searchTerm = this.searchTermTxt.trim()
    },
    searchTableCategory() {
      this.status = 'By Status'
      this.searchTermTxt = ''

      let category = this.category.toLowerCase()
      category = category.trim()
      if (category === 'by category') this.searchTerm = ''
      else this.searchTerm = category
    },

    searchTableStatus() {
      this.category = 'By Category'
      this.searchTermTxt = ''

      let status = this.status.toLowerCase()
      status = status.trim()
      if (status === 'by status') this.searchTerm = ''
      else this.searchTerm = status
    },

    sortRemoveDuplicate(a) {
      const result = []
      a.forEach(item => {
        if (result.indexOf(item) < 0) {
          result.push(item)
        }
      })
      return result
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker {
  width: 100% !important;
}

.invoice-register-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 30rem;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}
.filter-th{
  display: none;
}
.vgt-global-search{
  display: none;
}

.mobileVersion {
  display: none;
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .header-navbar.floating-nav {
      width: 86% !important;
      margin-right: 47px !important;
    }

  .upper-select1, .upper-select2, .upper-select3{
    margin-top: 5px; width: 352px !important;
  }
  .custom-search{
    float: left !important;
  }

  .mytable{ width: 100% !important; z-index: 0;}
  .upper-select{
    position: relative;
    z-index: 1;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {
  .header-navbar.floating-nav {
      width: 83% !important;
      margin-right: 60px !important;
    }

  .upper-select1, .upper-select2, .upper-select3{
    margin-top: 5px; width: 337px !important;
  }
  .custom-search{
    float: left !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {
  .header-navbar.floating-nav {
      width: 78% !important;
      margin-right: 83px !important;
    }

  .upper-select1, .upper-select2, .upper-select3{
    margin-top: 5px; width: 307px !important;
  }
  .custom-search{
    float: left !important;
  }

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {
  .header-navbar.floating-nav {
      width: 76% !important;
      margin-right: 96px !important;
  }

  .upper-select1, .upper-select2, .upper-select3{
    margin-top: 5px; width: 299px !important;
  }
  .custom-search{
    float: left !important;
  }
}

/* smaller phone */
@media only screen and (max-width: 365px)  {
  .header-navbar.floating-nav {
    width: 91% !important;
    margin-right: 16px !important;
  }

  .upper-select1, .upper-select2, .upper-select3{
    margin-top: 5px; width: 285px !important;
  }
  .custom-search{
    float: left !important;
  }
}
.dark-layout a {
  color: #fb50a1;
}

@media only screen and (max-width: 768px) {
  .mobileVersion {
    display: block;

    input, select {
      width: 20rem !important;
      margin: 5px auto;
    }
  }

  .desktopVersion {
    display: none;
  }

  .mytable {
    margin-top: 130px !important;
  }
}
</style>
